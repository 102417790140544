import DataManager from "@/model/data_manager";
// import Stone from "@/model/stone/stone";
import axios from 'axios';

class Inventory {
    public id:number = 0;
    public quantity:number = 0;
    constructor(id:number, quantity:number){
        this.id = id;
        this.quantity = quantity;
    }
}

export class OrderController{
    ///////////////////  SINGLETON  //////////////////////
    private static instance:OrderController;
    private constructor() { }
    public static getInstance(): OrderController {
        if (!OrderController.instance) {
            OrderController.instance = new OrderController();
        }
        return OrderController.instance;
    }

    public baseDomain:string = '';
    public localDomain:string = '';

    ///////////////////  PUBLIC  //////////////////////

    public setBaseDomain(path:string):void{
        this.baseDomain = path;
    }

    public setLocalDomain(path:string):void{
        this.localDomain = path;
    }
    

    /// Send the selected options to the server and return the
    /// price information (including line items)
    public async getDesignPrice():Promise<number>{
        const body = this.getSelectedData();
        const dm:DataManager = DataManager.getInstance();
        const url:string = (dm.path??'')+'/populate_cart/price';
        const { data, status } = await axios.post(
            url, body,
        );
        let total = 0;
        if(data.total){
            total+=data.total;
        }
        if(dm.selectedStone && !dm.selectedStone.vault){
            total += parseFloat(dm.selectedStone.price??'0.0');
        }
        console.log("order ", data);
        return total;
    }

    public async openCart():Promise<any>{
        const body = this.getSelectedData();
        const dm:DataManager = DataManager.getInstance();
        const url:string = (dm.path??'')+'/populate_cart/prod';
        const { data, status } = await axios.post(url, body);
        const cart:Array<string> = [];
        if(data.productId){
            cart.push(data.productId.toString());
        }
        //add stone
        if(dm.selectedStone && dm.selectedStone.id && !dm.selectedStone.vault){
            cart.push(dm.selectedStone.id.toString());
        } 
        const cartUrl = this.getCartUrl(cart)
        this.redirectToUrl(cartUrl);
    }

    public redirectHome(){
        this.redirectToUrl(this.baseDomain);
    }

    private currentUser:any|undefined = undefined;
    private email: string|undefined = undefined;
    private password: string|undefined = undefined;
    private tempUser: any|undefined = undefined;


    public async login(email:string, password:string, remeber:boolean = false):Promise<boolean>{
        this.email = email;
        this.password = password;
        const body = {username:email, password:password};
        const dm:DataManager = DataManager.getInstance();
        const url:string = (dm.path??'')+'/wp_site_auth/login';
        const { data, status } = await axios.post(url, body);
        if(data && data.status){
            if(data.status == 'ok'){
                //sucess 
                this.currentUser = data.data;
                if(remeber){
                    this.saveUser();
                }
                await this.updateVault();
                return true;
            }
        }
        return false;
    }
    public setTempUserDetails(tempUserDetails:any):void{
        this.tempUser = tempUserDetails;
    }

    public isLoggedIn():boolean{
        return this.currentUser != undefined;
    }

    public logOut(){
        this.currentUser = undefined;
        this.email = undefined;
        this.password = undefined;
        this.saveUser();
        const dm:DataManager = DataManager.getInstance();
        dm.clearVault();
    }

    public forgotPassword(){
        this.redirectToBase('/my-account/lost-password/');
    }

    public signup(){
        this.redirectToBase('/my-account/');
    }

    public redirectToBase(path:String){
        this.redirectToUrl(this.baseDomain+path);
    }

    public async updateVault():Promise<void>{
        if(this.isLoggedIn() && this.currentUser && this.currentUser.token){
            const dm:DataManager = DataManager.getInstance();
            await dm.updateVault(this.currentUser.token);
        } 
    }


/////////////////////////  session storage  ///////////////////////////

    private saveUser(){
        if(this.currentUser){
            const data = {
                email: this.email,
                password: this.password,
                user: this.currentUser
            };
            localStorage.setItem("session_details", JSON.stringify(data));
        }else{
            localStorage.removeItem("session_details");
        }
    }

    public userStored(){
        return localStorage.getItem("session_details") != null;
    }

    public async restoreUser():Promise<void>{
        if(localStorage.getItem("session_details")){



            const dataStr: string|null = localStorage.getItem("session_details");
            if(dataStr){
                const data = JSON.parse(dataStr);
                this.email = data.email;
                this.password = data.password;
                this.currentUser = data.user;

                // this.currentUser = JSON.parse(data.ur);
                if(this.email && this.password){
                    await this.login(this.email!, this.password!, true);
                }

                // await this.updateVault();
            }
        }
    }





/////////////////////////  Private  ///////////////////////////
    
    private getSelectedData():Record<string, any>{
        const dm:DataManager = DataManager.getInstance();
        const data: Record<string, any> = {};
       if(dm.selectedBand){
            data.bandID = dm.selectedBand.id;
            if(dm.selectedBand.image){
                data.productImage = dm.selectedBand.image.src();
            }
       }
       if(dm.selectedMetal){
        data.metalID = dm.selectedMetal.id;
        }
        if(dm.includeWeddingBand){
            data.includeWeddingBand = true;
        }
        if(dm.selectedSize){
            data.size = dm.selectedSize.name;
        }
        if(dm.engraving){
            data.engraving = dm.engraving;
            if(dm.selectedFontOption){
                data.engravingFont = dm.selectedFontOption.name??'';
            }
        }
        if(dm.selectedSettingMetal && dm.selectedSettingMetal != dm.selectedMetal){
            data.secondMetalID = dm.selectedSettingMetal.id;
        }
        if(dm.selectedDiamondOption){
            data.diamondID = dm.selectedDiamondOption.id;
        }
        if(dm.additionalRequests){
            data.additionalRequests = dm.additionalRequests;
        }

        if(dm.selectedStone){
            if(dm.selectedStone.vault){
                data.vaultStone = dm.selectedStone.short_description??'';
            }else{
                data.selectedStone = dm.selectedStone.short_description??'';
            }
        }
        if(this.isLoggedIn()){
            data.user = this.currentUser;
        }else if(this.tempUser){
            data.user = this.tempUser;
        }
        return data;
    }

    private redirectToUrl(url:string, target:string = '_blank'){
        window.open(url, target);
    }

    private getCartUrl(products: Array<String>):string{
        const productString = products.join(',')+'&quantity=1';
        return this.baseDomain+
        "/cart/?add-to-cart="+productString;
    }


}



